<template>
  <div class="p-4">
    <a-button
      class="float-right mb-3 d-flex align-items-center"
      type="primary"
      @click="show = !show"
    >
      <template #icon><i class="bi bi-plus mr-1 mb-1"></i></template>
      Add Role
    </a-button>
    <list-roles></list-roles>
    <a-modal
      v-model:visible="show"
      centered
      closable
      title="Add Role"
      :footer="null"
      destroyOnClose
    >
      <add-role @toggleModal="(value) => (show = value)"></add-role>
    </a-modal>
  </div>
</template>
<script>
import ListRoles from './ListRoles.vue';
import AddRole from './AddRole.vue';

export default {
  components: {
    ListRoles,
    AddRole,
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>
